/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BpAddress } from '../models/bp-address';
import { BpContact } from '../models/bp-contact';
import { BusinessParty } from '../models/business-party';
import { BusinessPartyExtended } from '../models/business-party-extended';

@Injectable({
  providedIn: 'root',
})
export class BusinessPartyControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation byBpType
   */
  static readonly ByBpTypePath = '/business-parties';

  /**
   * This method retrieves a list of business parties by their types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpType()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpType$Response(params?: {
    searchMethods?: Array<'MANUAL' | 'ELECTRONIC' | 'EMDIS' | 'UNKNOWN'>;
    suppliedSources?: Array<'DONOR' | 'CORD' | 'BOTH'>;
    contractStatuses?: Array<'CONTRACTED' | 'NON_CONTRACTED'>;
    types?: Array<'AC' | 'IC' | 'MFG' | 'BIO' | 'REG' | 'DC' | 'LP' | 'TC' | 'CBB' | 'CDS' | 'CCS' | 'BMC' | 'BMD' | 'CP' | 'BB'>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BusinessParty>>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.ByBpTypePath, 'get');
    if (params) {
      rb.query('searchMethods', params.searchMethods, {});
      rb.query('suppliedSources', params.suppliedSources, {});
      rb.query('contractStatuses', params.contractStatuses, {});
      rb.query('types', params.types, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessParty>>;
      })
    );
  }

  /**
   * This method retrieves a list of business parties by their types
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `byBpType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpType(params?: {
    searchMethods?: Array<'MANUAL' | 'ELECTRONIC' | 'EMDIS' | 'UNKNOWN'>;
    suppliedSources?: Array<'DONOR' | 'CORD' | 'BOTH'>;
    contractStatuses?: Array<'CONTRACTED' | 'NON_CONTRACTED'>;
    types?: Array<'AC' | 'IC' | 'MFG' | 'BIO' | 'REG' | 'DC' | 'LP' | 'TC' | 'CBB' | 'CDS' | 'CCS' | 'BMC' | 'BMD' | 'CP' | 'BB'>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BusinessParty>> {

    return this.byBpType$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>) => r.body as Array<BusinessParty>)
    );
  }

  /**
   * Path part for operation byBpGuids
   */
  static readonly ByBpGuidsPath = '/business-parties';

  /**
   * This method retrieves a list of business parties by their IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuids()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  byBpGuids$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BusinessParty>>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.ByBpGuidsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessParty>>;
      })
    );
  }

  /**
   * This method retrieves a list of business parties by their IDs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `byBpGuids$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  byBpGuids(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BusinessParty>> {

    return this.byBpGuids$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>) => r.body as Array<BusinessParty>)
    );
  }

  /**
   * Path part for operation byBpGuid
   */
  static readonly ByBpGuidPath = '/business-parties/{bpGuid}';

  /**
   * This method retrieves a business party by its ID and search method
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuid$Response(params: {
    bpGuid: string;
    searchMethod?: 'MANUAL' | 'ELECTRONIC' | 'EMDIS' | 'UNKNOWN';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<BusinessParty>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.ByBpGuidPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
      rb.query('searchMethod', params.searchMethod, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessParty>;
      })
    );
  }

  /**
   * This method retrieves a business party by its ID and search method
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `byBpGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuid(params: {
    bpGuid: string;
    searchMethod?: 'MANUAL' | 'ELECTRONIC' | 'EMDIS' | 'UNKNOWN';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<BusinessParty> {

    return this.byBpGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BusinessParty>) => r.body as BusinessParty)
    );
  }

  /**
   * Path part for operation byBpGuidExtended
   */
  static readonly ByBpGuidExtendedPath = '/business-parties/{bpGuid}/extended';

  /**
   * This method retrieves an extended business party by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `byBpGuidExtended()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuidExtended$Response(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<BusinessPartyExtended>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.ByBpGuidExtendedPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BusinessPartyExtended>;
      })
    );
  }

  /**
   * This method retrieves an extended business party by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `byBpGuidExtended$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  byBpGuidExtended(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<BusinessPartyExtended> {

    return this.byBpGuidExtended$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BusinessPartyExtended>) => r.body as BusinessPartyExtended)
    );
  }

  /**
   * Path part for operation findBusinessPartyOfUser
   */
  static readonly FindBusinessPartyOfUserPath = '/business-parties/of-user';

  /**
   * This method retrieves a list of business parties for a given user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBusinessPartyOfUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBusinessPartyOfUser$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BusinessParty>>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.FindBusinessPartyOfUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BusinessParty>>;
      })
    );
  }

  /**
   * This method retrieves a list of business parties for a given user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBusinessPartyOfUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBusinessPartyOfUser(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BusinessParty>> {

    return this.findBusinessPartyOfUser$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BusinessParty>>) => r.body as Array<BusinessParty>)
    );
  }

  /**
   * Path part for operation findCpBpAddress
   */
  static readonly FindCpBpAddressPath = '/business-parties/cp/addresses/{customerAccountId}/{addressId}';

  /**
   * This method retrieves a business party address by its customer account ID and address ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCpBpAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddress$Response(params: {
    customerAccountId: number;
    addressId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<BpAddress>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.FindCpBpAddressPath, 'get');
    if (params) {
      rb.path('customerAccountId', params.customerAccountId, {});
      rb.path('addressId', params.addressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BpAddress>;
      })
    );
  }

  /**
   * This method retrieves a business party address by its customer account ID and address ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findCpBpAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddress(params: {
    customerAccountId: number;
    addressId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<BpAddress> {

    return this.findCpBpAddress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BpAddress>) => r.body as BpAddress)
    );
  }

  /**
   * Path part for operation findCpBpAddressesByGuid
   */
  static readonly FindCpBpAddressesByGuidPath = '/business-parties/cp/addresses/{bpGuid}';

  /**
   * This method retrieves a list of business party addresses by its guid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCpBpAddressesByGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddressesByGuid$Response(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BpAddress>>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.FindCpBpAddressesByGuidPath, 'get');
    if (params) {
      rb.path('bpGuid', params.bpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BpAddress>>;
      })
    );
  }

  /**
   * This method retrieves a list of business party addresses by its guid
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findCpBpAddressesByGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCpBpAddressesByGuid(params: {
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BpAddress>> {

    return this.findCpBpAddressesByGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BpAddress>>) => r.body as Array<BpAddress>)
    );
  }

  /**
   * Path part for operation findBpContacts
   */
  static readonly FindBpContactsPath = '/business-parties/contacts/{customerAccountId}/{addressId}';

  /**
   * This method retrieves a list of business party address contacts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBpContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContacts$Response(params: {
    customerAccountId: number;
    addressId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<BpContact>>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.FindBpContactsPath, 'get');
    if (params) {
      rb.path('customerAccountId', params.customerAccountId, {});
      rb.path('addressId', params.addressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BpContact>>;
      })
    );
  }

  /**
   * This method retrieves a list of business party address contacts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBpContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContacts(params: {
    customerAccountId: number;
    addressId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<BpContact>> {

    return this.findBpContacts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<BpContact>>) => r.body as Array<BpContact>)
    );
  }

  /**
   * Path part for operation findBpContact
   */
  static readonly FindBpContactPath = '/business-parties/contacts/{customerAccountId}/{addressId}/{contactId}';

  /**
   * This method retrieves business party address contact by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBpContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContact$Response(params: {
    customerAccountId: number;
    addressId: number;
    contactId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<BpContact>> {

    const rb = new RequestBuilder(this.rootUrl, BusinessPartyControllerService.FindBpContactPath, 'get');
    if (params) {
      rb.path('customerAccountId', params.customerAccountId, {});
      rb.path('addressId', params.addressId, {});
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BpContact>;
      })
    );
  }

  /**
   * This method retrieves business party address contact by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBpContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBpContact(params: {
    customerAccountId: number;
    addressId: number;
    contactId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<BpContact> {

    return this.findBpContact$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<BpContact>) => r.body as BpContact)
    );
  }

}
