/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Disclaimer } from '../models/disclaimer';

@Injectable({
  providedIn: 'root',
})
export class DisclaimerControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation acknowledgeDisclaimer
   */
  static readonly AcknowledgeDisclaimerPath = '/disclaimers/{type}/acknowledge';

  /**
   * This method acknowledges a disclaimer with a given type for logged user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acknowledgeDisclaimer()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgeDisclaimer$Response(params: {
    type: 'CVDDNRFLTR';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DisclaimerControllerService.AcknowledgeDisclaimerPath, 'post');
    if (params) {
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method acknowledges a disclaimer with a given type for logged user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acknowledgeDisclaimer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acknowledgeDisclaimer(params: {
    type: 'CVDDNRFLTR';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.acknowledgeDisclaimer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDisclaimer
   */
  static readonly GetDisclaimerPath = '/disclaimers/{type}';

  /**
   * This method retrieves a disclaimer by type for logged user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDisclaimer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisclaimer$Response(params: {
    type: 'CVDDNRFLTR';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Disclaimer>> {

    const rb = new RequestBuilder(this.rootUrl, DisclaimerControllerService.GetDisclaimerPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Disclaimer>;
      })
    );
  }

  /**
   * This method retrieves a disclaimer by type for logged user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDisclaimer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDisclaimer(params: {
    type: 'CVDDNRFLTR';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Disclaimer> {

    return this.getDisclaimer$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Disclaimer>) => r.body as Disclaimer)
    );
  }

}
