/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CriticalAnomalyIncidentCreationRequest } from '../models/critical-anomaly-incident-creation-request';
import { TechnicalDifficultiesIncidentCreationRequest } from '../models/technical-difficulties-incident-creation-request';

@Injectable({
  providedIn: 'root',
})
export class IncidentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation create
   */
  static readonly CreatePath = '/incident/create';

  /**
   * Sends email to the Service Center mailbox with incident details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: {
    context?: HttpContext
    body: (CriticalAnomalyIncidentCreationRequest | TechnicalDifficultiesIncidentCreationRequest)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IncidentControllerService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends email to the Service Center mailbox with incident details.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: {
    context?: HttpContext
    body: (CriticalAnomalyIncidentCreationRequest | TechnicalDifficultiesIncidentCreationRequest)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.create$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation isAllowedToCreate
   */
  static readonly IsAllowedToCreatePath = '/incident/create/is-allowed';

  /**
   * Verifies if the context user is allowed to create an incident.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isAllowedToCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAllowedToCreate$Response(params: {
    type: 'TECH_DIFFICULTIES' | 'CRITICAL_STATUS_ANOMALY';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, IncidentControllerService.IsAllowedToCreatePath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Verifies if the context user is allowed to create an incident.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isAllowedToCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isAllowedToCreate(params: {
    type: 'TECH_DIFFICULTIES' | 'CRITICAL_STATUS_ANOMALY';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<boolean> {

    return this.isAllowedToCreate$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
