/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CtInstructionReport } from '../models/ct-instruction-report';
import { Contact } from '../models/contact';
import { CustomerAccount } from '../models/customer-account';
import { SampleInstructionTemplate } from '../models/sample-instruction-template';
import { TemplateItem } from '../models/template-item';

@Injectable({
  providedIn: 'root',
})
export class SampleInstructionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getInstructionReport
   */
  static readonly GetInstructionReportPath = '/sample/instruction';

  /**
   * Get instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInstructionReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstructionReport$Response(params: {
    bpGuid: string;
    isDomestic: boolean;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<CtInstructionReport>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.GetInstructionReportPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('isDomestic', params.isDomestic, {});
      rb.query('secCode', params.secCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CtInstructionReport>;
      })
    );
  }

  /**
   * Get instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInstructionReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInstructionReport(params: {
    bpGuid: string;
    isDomestic: boolean;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<CtInstructionReport> {

    return this.getInstructionReport$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<CtInstructionReport>) => r.body as CtInstructionReport)
    );
  }

  /**
   * Path part for operation updateInstructions
   */
  static readonly UpdateInstructionsPath = '/sample/instruction';

  /**
   * Update instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructions$Response(params: {
    context?: HttpContext
    body: CtInstructionReport
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.UpdateInstructionsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateInstructions(params: {
    context?: HttpContext
    body: CtInstructionReport
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateInstructions$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createInstructions
   */
  static readonly CreateInstructionsPath = '/sample/instruction';

  /**
   * Create instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInstructions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInstructions$Response(params: {
    context?: HttpContext
    body: CtInstructionReport
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.CreateInstructionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Create instructions for the specific bpGuid and secondaryCode
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createInstructions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createInstructions(params: {
    context?: HttpContext
    body: CtInstructionReport
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.createInstructions$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTemplate
   */
  static readonly GetTemplatePath = '/sample/instruction/template';

  /**
   * Get template with the specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate$Response(params: {
    type: 'DOMDOM' | 'INTLDOM' | 'DINTLINTL';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SampleInstructionTemplate>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.GetTemplatePath, 'get');
    if (params) {
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SampleInstructionTemplate>;
      })
    );
  }

  /**
   * Get template with the specific type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplate(params: {
    type: 'DOMDOM' | 'INTLDOM' | 'DINTLINTL';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SampleInstructionTemplate> {

    return this.getTemplate$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SampleInstructionTemplate>) => r.body as SampleInstructionTemplate)
    );
  }

  /**
   * Path part for operation updateTemplate
   */
  static readonly UpdateTemplatePath = '/sample/instruction/template';

  /**
   * Update template with the specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate$Response(params: {
    context?: HttpContext
    body: SampleInstructionTemplate
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.UpdateTemplatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update template with the specific type
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate(params: {
    context?: HttpContext
    body: SampleInstructionTemplate
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateTemplate$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAvailableTemplates
   */
  static readonly GetAvailableTemplatesPath = '/sample/instruction/template/list';

  /**
   * Get all available templates (id + type)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableTemplates$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<TemplateItem>>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.GetAvailableTemplatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TemplateItem>>;
      })
    );
  }

  /**
   * Get all available templates (id + type)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableTemplates(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<TemplateItem>> {

    return this.getAvailableTemplates$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<TemplateItem>>) => r.body as Array<TemplateItem>)
    );
  }

  /**
   * Path part for operation publishMessage
   */
  static readonly PublishMessagePath = '/sample/instruction/publish-message';

  /**
   * Request to publish Update Transplant Center Lab message to ESB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publishMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishMessage$Response(params: {
    bpGuid: string;
    tcc: string;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.PublishMessagePath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('tcc', params.tcc, {});
      rb.query('secCode', params.secCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request to publish Update Transplant Center Lab message to ESB
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `publishMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publishMessage(params: {
    bpGuid: string;
    tcc: string;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.publishMessage$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLabs
   */
  static readonly GetLabsPath = '/sample/instruction/labs/list';

  /**
   * Get all labs for the specific bpGuid and secondary code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabs$Response(params: {
    bpGuid: string;
    accountId?: number;
    shipToAddressId?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CustomerAccount>>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.GetLabsPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('accountId', params.accountId, {});
      rb.query('shipToAddressId', params.shipToAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerAccount>>;
      })
    );
  }

  /**
   * Get all labs for the specific bpGuid and secondary code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabs(params: {
    bpGuid: string;
    accountId?: number;
    shipToAddressId?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CustomerAccount>> {

    return this.getLabs$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CustomerAccount>>) => r.body as Array<CustomerAccount>)
    );
  }

  /**
   * Path part for operation deactivateTc
   */
  static readonly DeactivateTcPath = '/sample/instruction/deactivate';

  /**
   * Request deactivate tc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateTc()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTc$Response(params: {
    bpGuid: string;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.DeactivateTcPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('secCode', params.secCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request deactivate tc
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateTc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTc(params: {
    bpGuid: string;
    secCode?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deactivateTc$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLabContacts
   */
  static readonly GetLabContactsPath = '/sample/instruction/contact/list';

  /**
   * Get all contacts for the specific lab
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLabContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabContacts$Response(params: {
    bpGuid: string;
    customerAccountId: number;
    contactId?: number;
    shipToAddressId?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Contact>>> {

    const rb = new RequestBuilder(this.rootUrl, SampleInstructionControllerService.GetLabContactsPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('customerAccountId', params.customerAccountId, {});
      rb.query('contactId', params.contactId, {});
      rb.query('shipToAddressId', params.shipToAddressId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Contact>>;
      })
    );
  }

  /**
   * Get all contacts for the specific lab
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLabContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabContacts(params: {
    bpGuid: string;
    customerAccountId: number;
    contactId?: number;
    shipToAddressId?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Contact>> {

    return this.getLabContacts$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Contact>>) => r.body as Array<Contact>)
    );
  }

}
