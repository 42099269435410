/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LookerReportsDto } from '../models/looker-reports-dto';
import { LookerUrlDto } from '../models/looker-url-dto';

@Injectable({
  providedIn: 'root',
})
export class LookerControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUrl
   */
  static readonly GetUrlPath = '/looker/{reportId}/{tcId}';

  /**
   * This method retrieves looker's embed URL by reportId and tcId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrl$Response(params: {
    reportId: number;
    tcId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<LookerUrlDto>> {

    const rb = new RequestBuilder(this.rootUrl, LookerControllerService.GetUrlPath, 'get');
    if (params) {
      rb.path('reportId', params.reportId, {});
      rb.path('tcId', params.tcId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookerUrlDto>;
      })
    );
  }

  /**
   * This method retrieves looker's embed URL by reportId and tcId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrl(params: {
    reportId: number;
    tcId: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<LookerUrlDto> {

    return this.getUrl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<LookerUrlDto>) => r.body as LookerUrlDto)
    );
  }

  /**
   * Path part for operation getReports
   */
  static readonly GetReportsPath = '/looker/reports';

  /**
   * This method retrieves available looker reports' info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<LookerReportsDto>> {

    const rb = new RequestBuilder(this.rootUrl, LookerControllerService.GetReportsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookerReportsDto>;
      })
    );
  }

  /**
   * This method retrieves available looker reports' info
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<LookerReportsDto> {

    return this.getReports$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<LookerReportsDto>) => r.body as LookerReportsDto)
    );
  }

}
