/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProgramResponse } from '../models/program-response';

@Injectable({
  providedIn: 'root',
})
export class ProgramControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSupplierProgram
   */
  static readonly GetSupplierProgramPath = '/ebs-programs/supplier';

  /**
   * This method retrieves the info for CBB (Cord Blood Bank) if it participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplierProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierProgram$Response(params: {
    bpGuid: string;
    programName?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<ProgramResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProgramControllerService.GetSupplierProgramPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('programName', params.programName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramResponse>>;
      })
    );
  }

  /**
   * This method retrieves the info for CBB (Cord Blood Bank) if it participates in a given program
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupplierProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplierProgram(params: {
    bpGuid: string;
    programName?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<ProgramResponse>> {

    return this.getSupplierProgram$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>) => r.body as Array<ProgramResponse>)
    );
  }

  /**
   * Path part for operation getCustomerProgram
   */
  static readonly GetCustomerProgramPath = '/ebs-programs/customer';

  /**
   * This method retrieves the info for TC if it participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProgram$Response(params: {
    bpGuid: string;
    programName?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<ProgramResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProgramControllerService.GetCustomerProgramPath, 'get');
    if (params) {
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('programName', params.programName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramResponse>>;
      })
    );
  }

  /**
   * This method retrieves the info for TC if it participates in a given program
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProgram(params: {
    bpGuid: string;
    programName?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<ProgramResponse>> {

    return this.getCustomerProgram$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>) => r.body as Array<ProgramResponse>)
    );
  }

  /**
   * Path part for operation getAllSuppliersProgram
   */
  static readonly GetAllSuppliersProgramPath = '/ebs-programs/all-suppliers';

  /**
   * This method retrieves all suppliers CBB's(Cord Blood Bank) for a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSuppliersProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSuppliersProgram$Response(params: {
    programName: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<ProgramResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProgramControllerService.GetAllSuppliersProgramPath, 'get');
    if (params) {
      rb.query('programName', params.programName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramResponse>>;
      })
    );
  }

  /**
   * This method retrieves all suppliers CBB's(Cord Blood Bank) for a given program
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSuppliersProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSuppliersProgram(params: {
    programName: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<ProgramResponse>> {

    return this.getAllSuppliersProgram$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>) => r.body as Array<ProgramResponse>)
    );
  }

  /**
   * Path part for operation getAllCustomersProgram
   */
  static readonly GetAllCustomersProgramPath = '/ebs-programs/all-customers';

  /**
   * This method retrieves all TC's participates in a given program
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomersProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomersProgram$Response(params: {
    programName: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<ProgramResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, ProgramControllerService.GetAllCustomersProgramPath, 'get');
    if (params) {
      rb.query('programName', params.programName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramResponse>>;
      })
    );
  }

  /**
   * This method retrieves all TC's participates in a given program
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCustomersProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomersProgram(params: {
    programName: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<ProgramResponse>> {

    return this.getAllCustomersProgram$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<ProgramResponse>>) => r.body as Array<ProgramResponse>)
    );
  }

}
