/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Country } from '../models/country';
import { State } from '../models/state';

@Injectable({
  providedIn: 'root',
})
export class GeographyControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCountries
   */
  static readonly GetCountriesPath = '/geography/countries';

  /**
   * Retrieve list of available countries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<Country>>> {

    const rb = new RequestBuilder(this.rootUrl, GeographyControllerService.GetCountriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Country>>;
      })
    );
  }

  /**
   * Retrieve list of available countries
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountries(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<Country>> {

    return this.getCountries$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<Country>>) => r.body as Array<Country>)
    );
  }

  /**
   * Path part for operation getCountry
   */
  static readonly GetCountryPath = '/geography/countries/{countryCode}';

  /**
   * Get country by its two-letter code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountry$Response(params: {
    countryCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Country>> {

    const rb = new RequestBuilder(this.rootUrl, GeographyControllerService.GetCountryPath, 'get');
    if (params) {
      rb.path('countryCode', params.countryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Country>;
      })
    );
  }

  /**
   * Get country by its two-letter code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountry(params: {
    countryCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Country> {

    return this.getCountry$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Country>) => r.body as Country)
    );
  }

  /**
   * Path part for operation getStates
   */
  static readonly GetStatesPath = '/geography/countries/{countryCode}/states';

  /**
   * Get country's states by its code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates$Response(params: {
    countryCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<State>>> {

    const rb = new RequestBuilder(this.rootUrl, GeographyControllerService.GetStatesPath, 'get');
    if (params) {
      rb.path('countryCode', params.countryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<State>>;
      })
    );
  }

  /**
   * Get country's states by its code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStates(params: {
    countryCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<State>> {

    return this.getStates$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<State>>) => r.body as Array<State>)
    );
  }

  /**
   * Path part for operation getState
   */
  static readonly GetStatePath = '/geography/countries/{countryCode}/states/{stateCode}';

  /**
   * Get country's state by its code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getState$Response(params: {
    countryCode: string;
    stateCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<State>> {

    const rb = new RequestBuilder(this.rootUrl, GeographyControllerService.GetStatePath, 'get');
    if (params) {
      rb.path('countryCode', params.countryCode, {});
      rb.path('stateCode', params.stateCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<State>;
      })
    );
  }

  /**
   * Get country's state by its code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getState(params: {
    countryCode: string;
    stateCode: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<State> {

    return this.getState$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<State>) => r.body as State)
    );
  }

}
