/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressAutocompleteRequest } from '../models/address-autocomplete-request';
import { AddressAutocompleteResponse } from '../models/address-autocomplete-response';
import { AddressBase } from '../models/address-base';
import { AddressValidationResponse } from '../models/address-validation-response';

@Injectable({
  providedIn: 'root',
})
export class AddressValidationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation validateUsAddress
   */
  static readonly ValidateUsAddressPath = '/address-validation/validate-us';

  /**
   * Validate if the provided US address is valid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateUsAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUsAddress$Response(params: {
    context?: HttpContext
    body: AddressBase
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<AddressValidationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AddressValidationControllerService.ValidateUsAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressValidationResponse>;
      })
    );
  }

  /**
   * Validate if the provided US address is valid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateUsAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateUsAddress(params: {
    context?: HttpContext
    body: AddressBase
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<AddressValidationResponse> {

    return this.validateUsAddress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<AddressValidationResponse>) => r.body as AddressValidationResponse)
    );
  }

  /**
   * Path part for operation autocompleteUsAddress
   */
  static readonly AutocompleteUsAddressPath = '/address-validation/autocomplete-us';

  /**
   * Returns US address suggestions based on the provided address inputs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `autocompleteUsAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autocompleteUsAddress$Response(params: {
    context?: HttpContext
    body: AddressAutocompleteRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<AddressAutocompleteResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressValidationControllerService.AutocompleteUsAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AddressAutocompleteResponse>>;
      })
    );
  }

  /**
   * Returns US address suggestions based on the provided address inputs.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `autocompleteUsAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  autocompleteUsAddress(params: {
    context?: HttpContext
    body: AddressAutocompleteRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<AddressAutocompleteResponse>> {

    return this.autocompleteUsAddress$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<AddressAutocompleteResponse>>) => r.body as Array<AddressAutocompleteResponse>)
    );
  }

}
