/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NoteDto } from '../models/note-dto';

@Injectable({
  providedIn: 'root',
})
export class NoteControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getNote
   */
  static readonly GetNotePath = '/notes/{id}';

  /**
   * This method retrieves a note by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNote$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<NoteDto>> {

    const rb = new RequestBuilder(this.rootUrl, NoteControllerService.GetNotePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<NoteDto>;
      })
    );
  }

  /**
   * This method retrieves a note by its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNote(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<NoteDto> {

    return this.getNote$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<NoteDto>) => r.body as NoteDto)
    );
  }

  /**
   * Path part for operation updateNote
   */
  static readonly UpdateNotePath = '/notes/{id}';

  /**
   * This method updates a note with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNote$Response(params: {
    id: number;
    context?: HttpContext
    body: NoteDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NoteControllerService.UpdateNotePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method updates a note with a given ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNote(params: {
    id: number;
    context?: HttpContext
    body: NoteDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateNote$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteNote
   */
  static readonly DeleteNotePath = '/notes/{id}';

  /**
   * This method deletes a note with a given ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNote$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NoteControllerService.DeleteNotePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method deletes a note with a given ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNote(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deleteNote$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createNote
   */
  static readonly CreateNotePath = '/notes';

  /**
   * This method creates a note and returns its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNote$Response(params: {
    context?: HttpContext
    body: NoteDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, NoteControllerService.CreateNotePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method creates a note and returns its ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNote(params: {
    context?: HttpContext
    body: NoteDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<number> {

    return this.createNote$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getNotes
   */
  static readonly GetNotesPath = '/notes/all';

  /**
   * This method retrieves notes by their IDs specified in a list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNotes$Response(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<NoteDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NoteControllerService.GetNotesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NoteDto>>;
      })
    );
  }

  /**
   * This method retrieves notes by their IDs specified in a list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNotes(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<NoteDto>> {

    return this.getNotes$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<NoteDto>>) => r.body as Array<NoteDto>)
    );
  }

}
