/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AutoSaveDto } from '../models/auto-save-dto';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateOrCreateIfNotExist1
   */
  static readonly UpdateOrCreateIfNotExist1Path = '/autosaves';

  /**
   * This method updates auto saved user form or create if not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrCreateIfNotExist1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist1$Response(params: {
    context?: HttpContext
    body: AutoSaveDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<AutoSaveDto>> {

    const rb = new RequestBuilder(this.rootUrl, AutoSaveControllerService.UpdateOrCreateIfNotExist1Path, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutoSaveDto>;
      })
    );
  }

  /**
   * This method updates auto saved user form or create if not exist
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrCreateIfNotExist1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist1(params: {
    context?: HttpContext
    body: AutoSaveDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<AutoSaveDto> {

    return this.updateOrCreateIfNotExist1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<AutoSaveDto>) => r.body as AutoSaveDto)
    );
  }

  /**
   * Path part for operation get1
   */
  static readonly Get1Path = '/autosaves/{typeCode}';

  /**
   * This method retrieves auto saved user form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get1()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1$Response(params: {
    typeCode: 'PATENTRY' | 'DNRWKUP' | 'CORDSHP' | 'PATCPENTRY' | 'NEWWKUP' | 'SECWKUP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<AutoSaveDto>> {

    const rb = new RequestBuilder(this.rootUrl, AutoSaveControllerService.Get1Path, 'get');
    if (params) {
      rb.path('typeCode', params.typeCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutoSaveDto>;
      })
    );
  }

  /**
   * This method retrieves auto saved user form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get1(params: {
    typeCode: 'PATENTRY' | 'DNRWKUP' | 'CORDSHP' | 'PATCPENTRY' | 'NEWWKUP' | 'SECWKUP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<AutoSaveDto> {

    return this.get1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<AutoSaveDto>) => r.body as AutoSaveDto)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/autosaves/{typeCode}';

  /**
   * This method deletes auto saved user form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: {
    typeCode: 'PATENTRY' | 'DNRWKUP' | 'CORDSHP' | 'PATCPENTRY' | 'NEWWKUP' | 'SECWKUP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AutoSaveControllerService.DeletePath, 'delete');
    if (params) {
      rb.path('typeCode', params.typeCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method deletes auto saved user form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: {
    typeCode: 'PATENTRY' | 'DNRWKUP' | 'CORDSHP' | 'PATCPENTRY' | 'NEWWKUP' | 'SECWKUP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.delete$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
