/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageRecentHistoryPage } from '../models/page-recent-history-page';
import { RecentHistoryPage } from '../models/recent-history-page';

@Injectable({
  providedIn: 'root',
})
export class HistoryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation get
   */
  static readonly GetPath = '/recent-history';

  /**
   * This method retrieves user's recent history
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params?: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageRecentHistoryPage>> {

    const rb = new RequestBuilder(this.rootUrl, HistoryControllerService.GetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageRecentHistoryPage>;
      })
    );
  }

  /**
   * This method retrieves user's recent history
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params?: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageRecentHistoryPage> {

    return this.get$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageRecentHistoryPage>) => r.body as PageRecentHistoryPage)
    );
  }

  /**
   * Path part for operation updateOrCreateIfNotExist
   */
  static readonly UpdateOrCreateIfNotExistPath = '/recent-history';

  /**
   * This method updates recent history page for the user or create if not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrCreateIfNotExist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist$Response(params: {
    context?: HttpContext
    body: RecentHistoryPage
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<RecentHistoryPage>> {

    const rb = new RequestBuilder(this.rootUrl, HistoryControllerService.UpdateOrCreateIfNotExistPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecentHistoryPage>;
      })
    );
  }

  /**
   * This method updates recent history page for the user or create if not exist
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrCreateIfNotExist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrCreateIfNotExist(params: {
    context?: HttpContext
    body: RecentHistoryPage
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<RecentHistoryPage> {

    return this.updateOrCreateIfNotExist$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<RecentHistoryPage>) => r.body as RecentHistoryPage)
    );
  }

  /**
   * Path part for operation deleteHistoryForTransferredPatient
   */
  static readonly DeleteHistoryForTransferredPatientPath = '/recent-history/clear/transferred-patient/{patientId}/{fromBpGuid}';

  /**
   * This method deletes history for transferred patient
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHistoryForTransferredPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHistoryForTransferredPatient$Response(params: {
    patientId: string;
    fromBpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HistoryControllerService.DeleteHistoryForTransferredPatientPath, 'delete');
    if (params) {
      rb.path('patientId', params.patientId, {});
      rb.path('fromBpGuid', params.fromBpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method deletes history for transferred patient
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHistoryForTransferredPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHistoryForTransferredPatient(params: {
    patientId: string;
    fromBpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deleteHistoryForTransferredPatient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
